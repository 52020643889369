<template>
  <div class="row justify-content-center">
    <div class="col-10">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Nav -->
            <div class="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-1">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <img :src="detail.image" width="200" alt="" />
                </div>
              </div>
            </div>
            <!--end: Wizard Nav -->

            <!--begin: Wizard Body -->
            <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
              <!--begin: Wizard Form-->
              <div class="row">
                <div class="offset-xxl-2 col-xxl-8">
                  <form class="form" id="kt_form">
                    <!--begin: Wizard Step 1-->
                    <div
                      class="pb-5"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <!-- <h4 class="font-weight-bold text-dark">
                        {{ detail.name }}
                      </h4> -->

                      <div class="card-body">
                        <table class="table table-borderless">
                          <tr>
                            <td class="pr-1" width="130">
                              <span class="font-weight-bold">Judul</span>
                            </td>
                            <td class="pl-0">
                              <span class="font-weight-bold">:</span>
                            </td>
                            <td class="pl-2">
                              <span class="text-muted">{{ detail.title }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1" width="130">
                              <span class="font-weight-bold">Kategori</span>
                            </td>
                            <td class="pl-0">
                              <span class="font-weight-bold">:</span>
                            </td>
                            <td class="pl-2">
                              <span class="text-muted">{{
                                detail.announcement_category_name
                              }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1" width="130">
                              <span class="font-weight-bold">Konten</span>
                            </td>
                            <td class="pl-0">
                              <span class="font-weight-bold">:</span>
                            </td>
                            <td class="pl-2">
                              <span class="text-muted">{{
                                detail.content
                              }}</span>
                            </td>
                          </tr>
                          <tr>
                            <td class="pr-1" width="130">
                              <span class="font-weight-bold">Lampiran</span>
                            </td>
                            <td class="pl-0">
                              <span class="font-weight-bold">:</span>
                            </td>
                            <td class="pl-2">
                              <a
                                download="file"
                                target="_blank"
                                :href="detail.attachment"
                              >
                                <b-card
                                  no-body
                                  class="overflow-hidden card-attach-image"
                                  style="max-width: 540px"
                                >
                                  <b-row
                                    no-gutters
                                    :class="'align-items-center'"
                                  >
                                    <!-- <b-col md="2">
                                      <i
                                        class="fas fa-file-pdf fa-5x"
                                        v-if="checkExtension(detail.attachment) == 'pdf'"
                                      ></i>
                                      <i
                                        class="fas fa-file-word fa-5x"
                                        v-if="
                                         checkExtension(detail.attachment) == 'docx' || checkExtension(detail.attachment) == 'doc'
                                        "
                                      ></i>
                                    </b-col> -->
                                    <b-col md="10">
                                      <h5 class="ml-3 text-dark">
                                        {{ detail.attachment.split('__________')[1] }}
                                        <!-- {{ attachment_list_display[index] }} -->
                                      </h5>
                                      <span class="ml-3 text-muted">{{
                                        checkExtension(detail.attachment)
                                      }}</span>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </a>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <!--end: Wizard Step 1-->

                    <!--begin: Wizard Actions -->
                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div>
                        <b-button
                          type="button"
                          class="ml-2"
                          variant="primary"
                          @click="$router.push('/masters/announcements')"
                        >
                          Tutup
                        </b-button>
                        <b-button
                          type="button"
                          class="ml-2"
                          variant="success"
                          @click="
                            $router.push(
                              '/masters/announcements/edit/' + $route.params.id
                            )
                          "
                          v-if="btnAccess"
                        >
                          Edit
                        </b-button>
                      </div>
                    </div>
                    <!--end: Wizard Actions -->
                  </form>
                </div>
                <!--end: Wizard-->
              </div>
            </div>
            <!--end: Wizard Body -->
          </div>
          <!--end: Wizard-->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<style>
.wizard-steps {
  height: 230px;
  width: 230px;
}

.wizard-steps img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service.js";

export default {
  name: "master-announcements-detail",
  data() {
    return {
      detail: {},
      // access
      btnAccess: false,
    };
  },
  methods: {
    get() {
      ApiService.get("api/announcements/" + this.$route.params.id)
        .then((response) => {
          this.detail = response.data.data;
        })
        .catch(({ response }) => {
          //("responseerrorr", response);
        });
    },
    checkExtension(fileName){
      let extension = fileName.split('.')
      return extension[extension.length - 1]
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "8010") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Master", route: "/masters/settings" },
      { title: "Pengumuman", route: "/masters/announcements" },
      { title: "Detail Pengumuman" },
    ]);

    this.get();
    this.checkAccess();
  },
};
</script>
